import cx from 'classnames'
import { type MouseEvent, useState, useContext, useId } from 'react'

import { type SanityGiftCardFormModule } from '@data/sanity/queries/types/modules'
import { SiteContext } from '@lib/site-context'

import { ButtonVariant } from '@components/buttons/button'
import SimplePortableText from '@components/simple-portable-text'
import Logo from '@components/logo'
import ButtonLink from '@components/buttons/button-link'
import Icon from '@components/icon'

type GiftCardFormProps = Pick<
  SanityGiftCardFormModule,
  | 'heading'
  | 'text'
  | 'values'
  | 'defaultValue'
  | 'minusButtonLabel'
  | 'plusButtonLabel'
  | 'submitButtonLabel'
>

const GiftCardForm = ({
  heading,
  text,
  values,
  defaultValue,
  minusButtonLabel,
  plusButtonLabel,
  submitButtonLabel,
}: GiftCardFormProps) => {
  const { settings } = useContext(SiteContext)

  const [selectedValue, setSelectedValue] = useState<number | undefined>(
    defaultValue ?? values?.[0]
  )

  const id = useId()

  if (!values || values.length === 0) {
    return null
  }

  const handleMinusClick = (event: MouseEvent<HTMLElement>) => {
    event?.preventDefault()

    const defaultIndex = defaultValue ? values.indexOf(defaultValue) : 0
    const currentIndex = selectedValue
      ? values.indexOf(selectedValue)
      : defaultIndex
    const newIndex = Math.max(currentIndex - 1, 0)

    setSelectedValue(values[newIndex])
  }

  const handlePlusClick = (event: MouseEvent<HTMLElement>) => {
    event?.preventDefault()

    const defaultIndex = defaultValue ? values.indexOf(defaultValue) : 0
    const currentIndex = selectedValue
      ? values.indexOf(selectedValue)
      : defaultIndex
    const newIndex = Math.min(currentIndex + 1, values.length - 1)

    setSelectedValue(values[newIndex])
  }

  return (
    <section className="overflow-hidden text-pageText">
      <div
        className={cx(
          'relative container flex flex-col gap-12 py-12',
          'md:grid md:grid-cols-2 md:py-44',
          'xl:pt-52 xl:pb-56'
        )}
      >
        <div className="flex flex-col items-center space-y-7 text-center order-2 md:order-none">
          <div className="max-w-[600px] space-y-5">
            {heading && <h2 className="">{heading}</h2>}
            {text && <SimplePortableText content={text} />}
          </div>

          <div className="inline-flex justify-center bg-white gap-16 bg-opacity-50 px-5 py-[10px]">
            <div className="inline-flex items-end gap-x-4 py-2">
              <button
                aria-label={minusButtonLabel}
                onClick={handleMinusClick}
                className={cx(
                  'inline-flex items-center justify-center font-medium text-center leading-[1.2] text-sm p-[6px]',
                  'border rounded-full border-darkGray border-opacity-25 bg-transparent text-red',
                  'drop-shadow-lg transition-all',
                  'hover:bg-transparent hover:border-darkGray hover:border-opacity-50 hover:text-red disabled:opacity-50'
                )}
              >
                <Icon id={`gift-card-minus-icon-${id}`} name="Minus" />
              </button>

              <div className="is-h4 __leading-none">{selectedValue},-</div>

              <button
                aria-label={plusButtonLabel}
                onClick={handlePlusClick}
                className={cx(
                  'inline-flex items-center justify-center font-medium text-center leading-[1.2] text-sm p-[6px]',
                  'border rounded-full border-darkGray border-opacity-25 bg-transparent text-red',
                  'drop-shadow-lg transition-all',
                  'hover:bg-transparent hover:border-darkGray hover:border-opacity-50 hover:text-red disabled:opacity-50'
                )}
              >
                <Icon id={`gift-card-plus-icon-${id}`} name="Plus" />
              </button>
            </div>

            {!!settings?.lifePeaksAccountName && (
              <ButtonLink
                variant={ButtonVariant.FILLED}
                href={`https://order.lifepeaks.dk/${settings.lifePeaksAccountName}?amount=${selectedValue}#value`}
                className="text-[15px]"
              >
                {submitButtonLabel}
              </ButtonLink>
            )}
          </div>
        </div>

        <Logo
          className={cx(
            'max-w-[280px] mx-auto opacity-15',
            'md:absolute md:top-0 md:-right-1/4 md:w-2/3 md:max-w-none md:translate-y-[-13px]',
            'xl:right-[-16.6%]'
          )}
          type="symbol"
        />
      </div>
    </section>
  )
}

export default GiftCardForm
